<template>
    <v-card :loading="!loaded" min-height="400">
        <v-card-title>
            {{ $vuetify.lang.t('$vuetify.pages.dashboard.categories') }}
        </v-card-title>
        <v-row v-if="loaded" align="center" class="px-5">
            <v-col class="px-10" cols="6">
                <v-row v-for="(item, index) in items" :key="index" align="center" class="py-2">
                    <v-card :color="chartData.datasets[0].backgroundColor[index]" class="me-5" height="20" width="20"/>
                    <div class="headline">{{ item.name }}</div>
                </v-row>
            </v-col>
            <v-col cols="6">
                <kurcc-pie-chart :chartdata="chartData" :options="options"/>
            </v-col>
            <v-col v-for="(item, index) in items" :key="index" cols="4">
                <v-card :color="chartData.datasets[0].backgroundColor[index]" dark elevation="2">
                    <v-card-text>
                        <div>{{ item.sites_count }}</div>
                        <div>Total {{ item.name }}</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "KurccCategoryAnalyticsPieChart",
    components: {
        KurccPieChart: () => import('@/modules/dashboard/components/KurccPieChart')
    },
    data() {
        return {
            loaded: false,
            items: [],
            chartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: []
                    }
                ]
            },
            options: {
                responsive: true,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        // top: 10,
                        bottom: 20
                    }
                },
                legend: {
                    display: false
                }
            },
        }
    },
    methods: {
        randomColor: function () {
            let letters = '0123456789ABCDEF'.split('');
            // let letters = '9988776655FFFFFF'.split('');
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        setChartData: function (items) {
            items.forEach(item => {
                this.chartData.labels.push(item.name);
                this.chartData.datasets[0].backgroundColor.push(this.randomColor());
                this.chartData.datasets[0].data.push(item.sites_count);
            })
        },
    },
    created() {
        this.$store.dispatch('getCategoriesAnalytics').then(items => {
            this.setChartData(items)
            this.items = items
            this.loaded = true
        })
    }
}
</script>
